import apollo from "../../../clients/apollo";
import {
  getTournamentGQL,
  listMWCTournamentsGQL,
  listTournamentsGQL,
} from "../../queries/tournament";
import { IParamsGetTournament } from "../../../types/tournament";
import { QueryOptions } from "@apollo/client";
import { Query } from "../../../gql/graphql";

const apolloClient = apollo.getInstance();

export const getTournament = async (
  params: IParamsGetTournament,
  options: Partial<QueryOptions> = {}
): Promise<Query["getTournament"]> => {
  const res = await apolloClient.query({
    ...options,
    query: getTournamentGQL,
    variables: {
      getTournamentGetTournamentInput: { id: params.id },
      leaderboardsGetLeaderboardInput: {
        limit: params?.limit ?? 10,
      },
    },
  });

  if (res?.errors) {
    throw res?.errors[0];
  }
  return res.data.getTournament;
};

export const listTournaments = async (
  options: Partial<QueryOptions> = {},
  limit?: number,
  isMWC = false
): Promise<Query["listTournament"]> => {
  const res = await apolloClient.query({
    query: isMWC ? listMWCTournamentsGQL : listTournamentsGQL,
    variables: {
      listTournamentInput: { limit },
      leaderboardsGetLeaderboardInput: {
        limit: limit ?? 10,
      },
    },
    ...options,
  });

  return res.data.listTournament;
};
